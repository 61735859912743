<script>
import moment from 'moment'

export default {
  props: {
    currentEmpresa: { type: Object, required: true },
    listPedidos: { type: Array, required: true },
    isBusy: { type: Boolean, default: false },
  },
  data() {
    return {
      titleBody: 'Listagem das Compras',
      currentPedido: undefined,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { label: 'Nº Pedido', key: 'id', sortable: true, tdClass: "text-right", thClass: 'text-right' },
        { label: 'Nota Fiscal', key: 'nnf', sortable: true, tdClass: "text-right", thClass: 'text-center' },
        {
          label: "Fornecedor", key: "fornecedor.pes_apelido", sortable: true, size: '200px', tdClass: 'nameOfPersona', thClass: 'text-center',
           thStyle: { "min-width": "200px" }
        },
        { label: "Itens", key: "itensprepedido", sortable: true, thClass: 'text-center' },
        { label: "Datas", key: "data", sortable: true, thClass: 'text-center' },
        {
          label: "Situação", key: "situacao_id", sortable: true, tdClass: 'text-center', thClass: 'text-center'
        },
        {
          label: "Ações", key: "acoes", sortable: true, tdClass: 'text-center', thClass: 'text-center'
        }
      ],
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return (this.listPedidos.length) ? this.listPedidos.length : 0;
    },
    clonefoot() {
      return (this.listPedidos.length || this.listPedidos.length > 10) ? true : false;
    },
  },
  mounted() {
    this.$emit('newTitle', this.titleBody);
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    // eslint-disable-next-line no-unused-vars
    formatCurrency(value, key, item) {
      return (value) ? value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) : '';
    },
    formatDateBR(value) {
      return (value) ? moment(value).format('DD/MM/yyyy HH:mm') : '';
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    setPedido(pedido) {
      this.currentPedido = pedido;
      this.$emit('setPedido', pedido);
    },
    editar(pedido) {
      if (pedido.tpnf === 0) {
        this.$emit('editCompra', pedido);
      }
    },
    excluir(pedido) {
      this.$emit('doDelete', pedido);
    },
    estabelecendo(e){
      console.log(e)
      this.$emit('estabelecendo', e)
    }
  },
}
</script>

<template>
<div class="card-body">
  <div class="row mt-4">
    <!-- Start Limit -->
    <div class="col-sm-12 col-md-6">
      <div id="compra-table_length" class="dataTables_length">
        <label class="d-inline-flex align-items-center">
          {{ $t('mesages.table.filtered.view') }}&nbsp;
          <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;{{ $t('mesages.table.filtered.records') }}
        </label>
      </div>
    </div>
    <!-- End Limit -->
    <!-- Start Search -->
    <div class="col-sm-12 col-md-6">
      <div id="compra-table_filter" class="dataTables_filter text-md-right">
        <label class="d-inline-flex align-items-center">
          {{ $t('mesages.table.search') }}:
          <b-form-input
          v-model="filter"
          type="search"
          :placeholder="$t('mesages.table.search')+'...'"
          class="form-control form-control-sm ml-2"
          ></b-form-input>
        </label>
      </div>
    </div>
    <!-- End search -->
  </div>
   <!-- Start Table -->
   <div class="row mt-4">
    <div class="table-responsive mb-0">
      <b-table
       :items="listPedidos"
       :fields="fields"
       responsive="sm"
       :per-page="perPage"
       :current-page="currentPage"
       :sort-by.sync="sortBy"
       :sort-desc.sync="sortDesc"
       :filter="filter"
       :filter-included-fields="filterOn"
       @filtered="onFiltered"
       :hover=true
       :busy="isBusy"
       show-empty
       :empty-text="$t('mesages.table.empty')"
      >
        <template #table-busy>
            <div class="text-left text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> {{ $t('mesages.table.loader') }}...</strong>
            </div>
        </template>
        <template #row-details="row">
          <div class="card ml-5 w-50" v-if="row.item.entradas && row.item.entradas.length <= 0">
            Nenhum item encontrado
          </div>
          <div class="card ml-5 w-50" v-else>
            <div class="card-body">
              <b-table-simple hover small caption-top responsive>
                <b-thead>
                  <b-tr>
                    <b-th>ID</b-th>
                    <b-th>Produto</b-th>
                    <b-th>Qtde/UN</b-th>
                    <b-th>Preço</b-th>
                    <b-th>Custo</b-th>
                    <b-th>Referência</b-th>
                    <b-th>CFOP</b-th>
                    <b-th>Grupo</b-th>
                    <b-th>SubTotal</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="item in row.item.entradas" :key="item.id">
                    <b-th>{{ item.id }}</b-th>
                    <b-th>{{ item.produto.produto_nome }}</b-th>
                    <b-th class="text-right">{{ item.quantidade.toLocaleString(undefined, {minimumFractionDigits: 3}) }} {{ item.produto.um }}</b-th>
                    <b-th>{{ item.preco.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</b-th>
                    <b-th>{{ item.custo.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</b-th>
                    <b-th>{{ item.referencia }}</b-th>
                    <b-th class="text-cennter">{{ item.ent_cfop }}</b-th>
                    <b-th>{{ item.produto.grupo.descricaogrupo }}</b-th>
                    <b-th class="text-right">{{ item.subtotal.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</b-th>
                  </b-tr>
                </b-tbody>
                <b-tfoot>
                  <b-tr>
                    <b-td colspan="7" variant="secondary">
                      <h5>Total itens: {{ (row.item.entradas) ? row.item.entradas.length : '0' }}</h5>
                    </b-td>
                    <b-td colspan="2" variant="secondary" class="text-right">
                      <h5>Total: {{ (row.item.valortotalprod) ? row.item.valortotalprod.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) : '' }} </h5>
                    </b-td>
                  </b-tr>
                </b-tfoot>
              </b-table-simple>
            </div>
          </div>
        </template>
        <template #cell(fornecedor_id)="row">
          {{ (row.item.destinatario) ? row.item.destinatario.pes_apelido : 'Fornecedor não informado'}}
        </template>
        <template #cell(acoes)="row">
          <!-- `data.value` is the value after formatted by the Formatter -->
          <b-dropdown v-bind:id="'dropdown-'+ row.item.id" class="m-md-2">
            <template #button-content>
              <i data-v-6289eca4="" class="bx ri-menu-line"></i>
            </template>
            <b-dropdown-item v-b-modal.modal-compra @click="setPedido(row.item)"><i class="bx ri-eraser-fill"></i> Visualizar</b-dropdown-item>
            <b-dropdown-item @click="editar(row.item)"><i class="bx ri-edit-2-line"></i> Alterar</b-dropdown-item>
            <b-dropdown-item @click="estabelecendo(row.item)"><i class="bx ri-edit-2-line"></i> Estabelecer</b-dropdown-item>
            <b-dropdown-item @click="excluir(row.item)"><i class="bx ri-eraser-fill"></i> Excluir</b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </div>
   </div>
   <!-- End Table -->
   <!-- Start Pagination -->
  <div class="row mt-4">
    <div class="col">
      <div class="dataTables_paginate paging_simple_numbers float-right">
        <ul class="pagination pagination-rounded mb-0">
          <!-- pagination -->
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
        </ul>
      </div>
    </div>
  </div>
  <!-- End Pagination -->
  <!-- Start Modal Compra -->
  <b-modal
   hide-footer
   size="xxl"
   id="modal-compra"
   title-class="font-18"
  >
    <template #modal-header="{ close }">
      <div class="row flex-nowrap align-items-center px-4 pt-3 pb-2">
        <div class="col p-0" style="min-width: 0px;">
            <div class="d-flex align-items-center w-100">
                <button class="btn btn-link"><i @click="close()" title="Voltar" class="fas fa-arrow-left mr-3"></i></button>
                <i title="Compra" class="mb-1 mr-1 fas fa-industry h5"></i> 
                <div class="h5 d-flex align-items-center px-2 rounded text-truncate detail-header-title">
                  Número da Nota: {{ currentPedido.nnf }} 
                </div>
                <h5 class="pl-2"><b-badge variant="secondary" class="pt-1 mr-2 ml-1">{{ formatterCurrBR(currentPedido.valortotalnota) }}</b-badge></h5>
            </div>
        </div>
    </div>
    </template>

    <Detail :compra="currentPedido" />
  </b-modal>
  <!-- End Modal Compra -->
</div>
</template>

<style>
.nameOfPersona {
  min-width: 350px;
}
.modal-xxl {
    max-width: 100%;
    margin: 20px 32px 0px 24px;
}
</style>