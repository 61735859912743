<script>
import moment from "moment";
import appConfig from "@/app.config";
import { http } from "@/helpers/easyindustriaapi/config";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import List from "./list";
import widget from "./widget.vue";
import Multiselect from "vue-multiselect";

export default {
  page: {
    title: "Listar Compras",
    meta: [{ name: "description", content: appConfig.description }],
  },
  //
  components: { Multiselect, widget, Layout, PageHeader, List },
  data() {
    return {
      gerar: {
        prazo_dias: 30,
        dividir: 1,
      },
      fatura_vet: [],
      select_comprador:null,
      select_plano:null,
      fields_fatura: [
        // {
        //   label: "id_pag",
        //   key: "id_pag",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        {
          label: "Vencimento",
          key: "vecimento",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Esp Pagamento",
          key: "esp_pag",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Conta",
          key: "conta",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "DF",
          key: "tp_pagamento",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "N doc",
          key: "n_doc",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Descrição",
          key: "desc",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Valor",
          key: "valor",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
        {
          label: "Ações",
          key: "excluir",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // { label: "Ações", key: "excluir2", tdClass: 'text-center', thClass: 'text-center' },
      ],
      loader: {
        get: false,
      },
      currentUser: null,
      currentEmpresa: null,
      currentPedido: {
        id: null,
        pessoa_id: null,
        nnf: null,
        tpnf: 0,
        tipopgto_id: null,
        qtde_parcelas: 1,
        historicopdr_id: null,
        tpmovimento_id: null,
        loc_id: null,
      },
      searchCompras: {},
      titleBody: "Listar Compras",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Pedidos",
          href: "/financeiro/pedidos",
          active: true,
        },
      ],
      hideSearches: false,
      listPreCompra: [],
      estabelece_veri: null,
      valores_add: false,
      tot_sub: "Total",
      finalizando_estabelece: false,
      faturando: false,
      saldos: [
        {
          id: 1,
          saldo: "200,00",
        },
        {
          id: 2,
          saldo: "400,00",
        },
      ],
      planos: [
        {
          id: 1,
          descricao: "plano 1",
        },
        {
          id: 2,
          descricao: "plano 2",
        },
        {
          id: 3,
          descricao: "plano 3",
        },
      ],
      faturando_compra: false,
      tp_pagamentos: [],
      tp_contas: [],
      sigla: "+",
      test_fun_rural: true,
      outro_valor: {
        bs_calculo: null,
        taxa: null,
        red_inss: null,
        frete: null,
        desconto: null,
        funrural: null,
      },
      item: {
        qtd_recebida: null,
        qtd_final: null,
        preco: 0,
        valor: 0,
        aliquota: null,
        Cst: null,
      },
      inicial: {
        tipo: null,
        fornecedor: null,
        data: null,
        nota: null,
        valor: null,
      },
      total_valor: 0,
      total_valor2: 0,
      observacoes: null,

      tipos: [
        {
          id: 1,
          nome: "materia prima comum",
        },
        {
          id: 2,
          nome: "materia não prima comum",
        },
      ],
      select_tipo: null,
      pessoas: [],
      select_pessoas: null,
    };
  },
  computed: {},
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user"));
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
    this.searchCompras.fromData = moment()
      .startOf("month")
      .format("YYYY-MM-DD HH:mm:ss");
    this.searchCompras.toData = moment()
      .endOf("month")
      .format("YYYY-MM-DD HH:mm:ss");
    this.currentPedido.dh_saient = this.searchCompras.toData;
    this.currentPedido.dataemissao = this.searchCompras.toData;
  },
  mounted() {
    this.getData(); // Lemvbrar que posso pegar o vetor de produtos pelo metodo get,
    this.get_pessoas();
    this.get_plano_conta();
    this.get_tipo_pagamento();
  },
  methods: {
    // tem os fornecedores nas pessoas
    select_tp_pagamento(){

    },
    select_conta(){

    },
    Delete_fatura() {
      this.fatura_vet.pop();
    },
    gerar_parcelas(){
     
      //   const total = parseFloat(
      //   this.inicial_prePedido.total_pedidos
      //     .replace(/\./g, "")
      //     .replace(",", ".")
      // );
     // const resultado = this.formatterCurrBR(total / this.gerar.dividir);
      // esvazia o vetor toda veze que chammo a função
      this.fatura_vet = [];
      for (let i = 1; i <= this.gerar.dividir; i++) {
        const parcela1 = new Date();
        parcela1.setDate(parcela1.getDate() + 30 * i);
        const ano = parcela1.getFullYear();
        const mes = parcela1.getMonth() + 1; // Lembrando que os meses são zero-based
        const dia = parcela1.getDate() + 1;
        const dataFormatada = `${ano}-${mes.toString().padStart(2, "0")}-${dia
          .toString()
          .padStart(2, "0")}`;
        this.fatura_vet.push({
          vecimento: dataFormatada,
        });
      }
  

    },
    pesquisa_pessoa(prePedido) {
      console.log(prePedido);
      let fornecedor_selecionado = this.pessoas.find(
        (p) => p.id === prePedido.fornecedor_id
      );
      this.select_pessoas = fornecedor_selecionado;
    },
    async get_tipo_pagamento() {
      try {
        let response = await http.get(
          "/tipopagamento?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.tp_pagamentos = response.data;
          //console.log(this.fornecedores_pessoa)
          //console.log(this.fornecedores_pessoa)
          setTimeout(() => {
            this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.tp_pagamentos = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async get_pessoas() {
      try {
        let response = await http.get(
          "/pessoa?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.pessoas = response.data;
          //console.log(this.fornecedores_pessoa)
          //console.log(this.fornecedores_pessoa)
          setTimeout(() => {
            this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.pessoas = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async get_plano_conta() {
      try {
        let response = await http.get(
          "/plano_conta?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.tp_contas = response.data;
          //console.log(this.fornecedores_pessoa)
          //console.log(this.fornecedores_pessoa)
          setTimeout(() => {
            this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.tp_contas = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    changeHomeTitle(newTitle) {
      this.titleBody = newTitle;
    },
    back() {
      this.estabelece_veri = false;
      this.$router.go(-1);
    },
    onLoader() {
      if (document.getElementById("preloader").style.display === "none") {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";
      }
    },
    offLoader() {
      if (document.getElementById("preloader").style.display === "block") {
        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      }
    },
    searchInputs() {
      this.hideSearches = !this.hideSearches;
    },
    async getData() {
      this.onLoader();
      try {
        let toParams = {
          nnf: this.searchCompras.nnf,
          toNNf: this.searchCompras.toNNf,
          fromData: this.searchCompras.fromData
            ? moment(this.searchCompras.fromData).format("yyyy-MM-DD HH:mm:ss")
            : null,
          toData: this.searchCompras.toData
            ? moment(this.searchCompras.toData).format("yyyy-MM-DD HH:mm:ss")
            : null,
          destinatario: this.searchCompras.destinatario,
        };
        let response = await http.get(
          "/pre-pedido?empresa_id=" + this.currentEmpresa.id,
          {
            params: toParams,
          }
        );
        if (response.status === 200) {
          this.listPreCompra = response.data;
          //console.log(this.listPreCompra)
          setTimeout(() => {
            this.offLoader();
          }, 2000);
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast(
              "danger",
              "Erro 406: " + messageErro.nnf
                ? messageErro.pessoa_id
                : messageErro.tipo_id
            );
            this.offLoader();
            break;
          case 404:
            this.makeToast(
              "danger",
              "Erro 404: endpoint não encontrado ou servidor fora do ar"
            );
            this.offLoader();
            break;

          default:
            this.makeToast("danger", error.message);
            this.offLoader();
            break;
        }
        this.loader.get = false;
      }

      this.loader.get = false;
    },
    estabelecendo_voltar() {
      this.estabelece_veri = false;
    },
    estabelecendo(e) {
      this.estabelece_veri = true;
      console.log(this.estabelece_veri);
      ///  console.log(e);
      this.item.valor = this.formatterCurrBR(e.valor);
      this.inicial.data = e.data;
      this.inicial.valor = this.formatterCurrBR(e.valor);
      this.total_valor = this.formatterCurrBR(e.valor);
      this.inicial.nota = e.nnf;
      this.observacoes = e.obervacoes;
      this.pesquisa_pessoa(e);
      // console.log(this.observacoes);
      // console.log(this.inicial.data);
    },
    outros_valores(num) {
      // console.log(num)
      // console.log(num)
      if (num == 1) {
        this.tot_sub = "Subtotal";
        this.valores_add = true;
      } else {
        this.tot_sub = "Total";
        this.valores_add = false;
      }
    },
    finalizando() {
      console.log("finalizando");
      this.finalizando_estabelece = true;
    },
    finalizando_back() {
      this.finalizando_estabelece = false;
    },
    faturando_abre() {
      this.faturando = true;
    },
    faturando_fecha() {
      this.faturando = false;
    },
    fat_compra_open_modal() {
      this.faturando_compra = true;
    },
    fat_compra_open_modal_fecha() {
      this.faturando_compra = false;
    },
    post_faturamento() {
      console.log("Salvando Faturamento");
      this.makeToast("suucess", "salvando Faturamento");
    },
    funrural(e) {
      if (e == 1) {
        this.test_fun_rural = false;
        this.sigla = "-";
      } else {
        this.outro_valor.funrural = null;
        this.test_fun_rural = true;
        this.sigla = "+";
      }
    },

    // outro_valor: {
    //     bs_calculo: null,
    //     taxa: null,
    //     red_inss: null,
    //     frete: null,
    //     desconto: null,
    //     funrural: null,
    //   }
    formatInput_bs_calculo() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.outro_valor.bs_calculo.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.outro_valor.bs_calculo = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    formatInput_taxa() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.outro_valor.taxa.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.outro_valor.taxa = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    formatInput_red_inss() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.outro_valor.red_inss.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.outro_valor.red_inss = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
      this.reducao_Desconto();
    },
    formatInput_frete() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.outro_valor.frete.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.outro_valor.frete = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
      this.reducao_Desconto();
    },
    formatInput_desconto() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.outro_valor.desconto.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.outro_valor.desconto = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
      this.reducao_Desconto();
    },
    formatInput_funrural() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.outro_valor.funrural.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.outro_valor.funrural = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
      this.reducao_Desconto();
      //this.reducao_funrural();
    },
    formatInput_qtd_recebida() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.item.qtd_recebida.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.item.qtd_recebida = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    formatInput_qtd_final() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.item.qtd_final.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.item.qtd_final = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    formatInput_preco() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.item.preco.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.item.preco = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    formatInput_valor() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.item.valor.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.item.valor = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    formatInput_aliquota() {
      // Remova todos os caracteres não numéricos do aliquota de entrada
      let numericValue = this.item.aliquota.replace(/\D/g, "");
      // Formate o aliquota numérico para a moeda desejada (por exemplo, BRL)
      this.item.aliquota = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    formatInput_Cst() {
      // Remova todos os caracteres não numéricos do Cst de entrada
      let numericValue = this.item.Cst.replace(/\D/g, "");
      // Formate o Cst numérico para a moeda desejada (por exemplo, BRL)
      this.item.Cst = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    calcular() {
      let num = this.item.qtd_final;
      let num2 = this.item.preco;
      // remove todos os pontos do valor fornceido
      // retira os pontos do valor , e substiuia a virgual por ponto
      if (num != null) {
        var num1 = num.replace(/\./g, "").replace(",", ".");
      }
      if (this.item.preco != 0) {
        var preco1 = num2.replace(/\./g, "").replace(",", ".");
      }
      if (!isNaN(preco1) && !isNaN(num1)) {
        let valor_final = parseFloat(num1) * parseFloat(preco1);
        // console.log(valor_final);
        this.item.valor = this.formatterCurrBR(valor_final);
        this.total_valor = this.formatterCurrBR(valor_final);
        this.total_valor2 = this.formatterCurrBR(valor_final);
      }
    },
    isFloat(n) {
      return Number(n) === n && n % 1 !== 0;
    },
    // reducao_funrural() {

    //     let total1 = this.total_valor.replace(/\./g, "").replace(",", ".");
    //     let total2 = this.total_valor2.replace(/\./g, "").replace(",", ".");
    //     let temp = this.total_valor2
    //     if()
    //     let funrual = this.outro_valor.funrural
    //       .replace(/\./g, "")
    //       .replace(",", ".");
    //     console.log(total1);
    //     console.log(total2);
    //     let valor_final = parseFloat(total2) - parseFloat(funrual);
    //     console.log(valor_final);
    //     this.total_valor2 = valor_final;

    // },
    // verificar no funnrual se ja tem um valor no desnconto,
    // se tiver reduz o funrual mais o desconto;

    // else if(red_inss != null && funrual != null ){
    //     let funrual1 = this.outro_valor.funrural.replace(/\./g, "").replace(",", ".")
    //     let red_inss1 = this.outro_valor.red_inss.replace(/\./g, "").replace(",", ".")
    //     let reducao = parseFloat(total) - (parseFloat(desconto) + parseFloat(funrual1) + parseFloat(red_inss1));
    //      this.total_valor2 = this.formatterCurrBR(reducao);
    // } else if(red_inss == null && funrual != null){
    //     let funrual1 = this.outro_valor.funrural.replace(/\./g, "").replace(",", ".")
    //     // let red_inss1 = this.outro_valor.red_inss.replace(/\./g, "").replace(",", ".")
    //     let reducao = parseFloat(total) - (parseFloat(desconto) + parseFloat(funrual1) );
    //      this.total_valor2 = this.formatterCurrBR(reducao);
    // } else if(red_inss != null && funrual == null){
    //   //let funrual1 = this.outro_valor.funrural.replace(/\./g, "").replace(",", ".")
    //      let red_inss1 = this.outro_valor.red_inss.replace(/\./g, "").replace(",", ".")
    //     let reducao = parseFloat(total) - (parseFloat(desconto) + parseFloat(red_inss1) );
    //      this.total_valor2 = this.formatterCurrBR(reducao);
    // }
    reducao_Desconto() {
      // let total = this.total_valor2;
      let desconto = this.outro_valor.desconto;
      // total = this.total_valor.replace(/\./g, "").replace(",", ".");
      // desconto = this.outro_valor.desconto.replace(/\./g, "").replace(",", ".");
      // funrual = this.outro_valor.funrural;
      let funrual = this.outro_valor.funrural;
      let red_inss = this.outro_valor.red_inss;
      let frete = this.outro_valor.frete;
      console.log(red_inss);
      if (funrual == null && red_inss == null && desconto != null) {
        console.log("entrou no 1");

        if (this.outro_valor.frete != null) {
          let total = this.total_valor.replace(/\./g, "").replace(",", ".");
          let desconto1 = this.outro_valor.desconto
            .replace(/\./g, "")
            .replace(",", ".");
          let frete01 = this.outro_valor.frete
            .replace(/\./g, "")
            .replace(",", ".");
          let reducao =
            parseFloat(total) + parseFloat(frete01) - parseFloat(desconto1);
          this.total_valor2 = this.formatterCurrBR(reducao);
        } else {
          let total = this.total_valor.replace(/\./g, "").replace(",", ".");
          let desconto1 = this.outro_valor.desconto
            .replace(/\./g, "")
            .replace(",", ".");
          let reducao = parseFloat(total) - parseFloat(desconto1);
          this.total_valor2 = this.formatterCurrBR(reducao);
        }
      } else if (funrual != null && desconto != null && red_inss == null) {
        console.log("Entrou no 2");
        if (this.outro_valor.frete != null) {
          let total = this.total_valor.replace(/\./g, "").replace(",", ".");
          let desconto2 = this.outro_valor.desconto
            .replace(/\./g, "")
            .replace(",", ".");
          let funrural2 = this.outro_valor.funrural
            .replace(/\./g, "")
            .replace(",", ".");
          let frete002 = this.outro_valor.frete
            .replace(/\./g, "")
            .replace(",", ".");

          let reducao =
            parseFloat(total) +
            parseFloat(frete002) -
            (parseFloat(desconto2) + parseFloat(funrural2));
          // console.log(reducao);
          this.total_valor2 = this.formatterCurrBR(reducao);
        } else {
          let total = this.total_valor.replace(/\./g, "").replace(",", ".");
          let desconto2 = this.outro_valor.desconto
            .replace(/\./g, "")
            .replace(",", ".");
          let funrural2 = this.outro_valor.funrural
            .replace(/\./g, "")
            .replace(",", ".");
          let reducao =
            parseFloat(total) - (parseFloat(desconto2) + parseFloat(funrural2));
          // console.log(reducao);
          this.total_valor2 = this.formatterCurrBR(reducao);
        }
      } else if (funrual == null && desconto == null && red_inss != null) {
        console.log("entrou no 3");

        if (this.outro_valor.frete != null) {
          let total = this.total_valor.replace(/\./g, "").replace(",", ".");
          let inss = this.outro_valor.red_inss
            .replace(/\./g, "")
            .replace(",", ".");
          let frete003 = this.outro_valor.frete
            .replace(/\./g, "")
            .replace(",", ".");
          let reducao =
            parseFloat(total) + parseFloat(frete003) - parseFloat(inss);
          this.total_valor2 = this.formatterCurrBR(reducao);
        } else {
          let total = this.total_valor.replace(/\./g, "").replace(",", ".");
          let inss = this.outro_valor.red_inss
            .replace(/\./g, "")
            .replace(",", ".");
          let reducao = parseFloat(total) - parseFloat(inss);
          // console.log(reducao);
          this.total_valor2 = this.formatterCurrBR(reducao);
        }
      } else if (funrual == null && desconto != null && red_inss != null) {
        console.log("entrou no 4");

        if (this.outro_valor.frete != null) {
          let total = this.total_valor.replace(/\./g, "").replace(",", ".");
          let inss = this.outro_valor.red_inss
            .replace(/\./g, "")
            .replace(",", ".");
          let desconto4 = this.outro_valor.desconto
            .replace(/\./g, "")
            .replace(",", ".");
          let frete004 = this.outro_valor.frete
            .replace(/\./g, "")
            .replace(",", ".");
          let reducao =
            parseFloat(total) +
            parseFloat(frete004) -
            (parseFloat(inss) + parseFloat(desconto4));
          // console.log(reducao);
          this.total_valor2 = this.formatterCurrBR(reducao);
        } else {
          let total = this.total_valor.replace(/\./g, "").replace(",", ".");
          let inss = this.outro_valor.red_inss
            .replace(/\./g, "")
            .replace(",", ".");
          let desconto4 = this.outro_valor.desconto
            .replace(/\./g, "")
            .replace(",", ".");
          let reducao =
            parseFloat(total) - (parseFloat(inss) + parseFloat(desconto4));
          // console.log(reducao);
          this.total_valor2 = this.formatterCurrBR(reducao);
        }
      } else if (funrual != null && desconto != null && red_inss != null) {
        console.log("entrou no 5");
        if (this.outro_valor.frete != null) {
          let total = this.total_valor.replace(/\./g, "").replace(",", ".");
          let inss = this.outro_valor.red_inss
            .replace(/\./g, "")
            .replace(",", ".");
          let desconto5 = this.outro_valor.desconto
            .replace(/\./g, "")
            .replace(",", ".");
          let rural = this.outro_valor.funrural
            .replace(/\./g, "")
            .replace(",", ".");
          let frete005 = this.outro_valor.frete
            .replace(/\./g, "")
            .replace(",", ".");

          let reducao =
            parseFloat(total) +
            parseFloat(frete005) -
            (parseFloat(inss) + parseFloat(desconto5) + parseFloat(rural));
          // console.log(reducao);
          this.total_valor2 = this.formatterCurrBR(reducao);
        } else {
          let total = this.total_valor.replace(/\./g, "").replace(",", ".");
          let inss = this.outro_valor.red_inss
            .replace(/\./g, "")
            .replace(",", ".");
          let desconto5 = this.outro_valor.desconto
            .replace(/\./g, "")
            .replace(",", ".");
          let rural = this.outro_valor.funrural
            .replace(/\./g, "")
            .replace(",", ".");
          let reducao =
            parseFloat(total) -
            (parseFloat(inss) + parseFloat(desconto5) + parseFloat(rural));
          // console.log(reducao);
          this.total_valor2 = this.formatterCurrBR(reducao);
        }
      } else if (funrual != null && desconto == null && red_inss == null) {
        console.log("entrou no 6");
        if (this.outro_valor.frete != null) {
          let total = this.total_valor.replace(/\./g, "").replace(",", ".");
          let rural = this.outro_valor.funrural
            .replace(/\./g, "")
            .replace(",", ".");
          let frete006 = this.outro_valor.frete
            .replace(/\./g, "")
            .replace(",", ".");
          let reducao =
            parseFloat(total) + parseFloat(frete006) - parseFloat(rural);
          this.total_valor2 = this.formatterCurrBR(reducao);
        } else {
          let total = this.total_valor.replace(/\./g, "").replace(",", ".");
          let rural = this.outro_valor.funrural
            .replace(/\./g, "")
            .replace(",", ".");
          let reducao = parseFloat(total) - parseFloat(rural);
          // console.log(reducao);
          this.total_valor2 = this.formatterCurrBR(reducao);
        }
      } else if (
        funrual == null &&
        desconto == null &&
        red_inss == null &&
        frete != null
      ) {
        console.log("entrou no 7");
        let total = this.total_valor.replace(/\./g, "").replace(",", ".");
        let frete1 = this.outro_valor.frete
          .replace(/\./g, "")
          .replace(",", ".");
        let soma = parseFloat(total) + parseFloat(frete1);
        // this.total_valor = this.formatterCurrBR(soma);
        this.total_valor2 = this.formatterCurrBR(soma);
      }
    },
    // soma_frete(){
    //   let total  = this.total_valor.replace(/\./g, "").replace(",", ".");
    //   let frete = this.outro_valor.frete.replace(/\./g, "").replace(",", ".")
    //   let soma =  parseFloat(total) + parseFloat(frete)
    //   this.total_valor2 = this.formatterCurrBR(soma);
    // }
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="titleBody" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <button class="btn btn-light" @click="searchInputs()">
                  <span
                    class="fa-sm fa"
                    :class="hideSearches ? 'fa-chevron-up' : 'fa-chevron-down'"
                  ></span>
                  Busca avançada
                </button>
              </div>
              <div class="col-sm-12 col-md-12 text-md-right">
                <button class="btn btn-secondary mr-2" @click="back()">
                  Voltar
                </button>
                <!-- <button class="btn btn-secondary" @click="estabelecendo()">Estabelecer</button> -->
              </div>
            </div>
          </div>
          <div class="card-body" v-if="hideSearches">
            <!-- Start Card -->
            <div class="card border border-primary">
              <div class="card-body">
                <h4 class="card-title">Filtros</h4>
                <p class="card-title-desc">
                  Use esses campos para filtrar os registros
                </p>
                <form class="needs-validation" name="search">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="searchCompras-status">N.º do pedido</label>
                        <div class="row">
                          <div class="col-md-6">
                            <input
                              id="searchCompras-id"
                              v-model="searchCompras.id"
                              type="number"
                              class="form-control"
                              placeholder="ID do Pré-Pedido"
                            />
                          </div>
                          <div class="col-md-6">
                            <input
                              id="searchCompras-nnf"
                              v-model="searchCompras.nnf"
                              type="number"
                              class="form-control"
                              placeholder="Nota Fiscal"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="searchCompras-fromData">Data saída</label>
                        <div class="row">
                          <div class="col-md-6">
                            <input
                              id="searchCompras-fromData"
                              v-model="searchCompras.fromData"
                              type="datetime-local"
                              class="form-control"
                              placeholder="dd/mm/aaaa hh:mm"
                            />
                          </div>
                          <div class="col-md-6">
                            <input
                              id="searchCompras-toData"
                              v-model="searchCompras.toData"
                              type="datetime-local"
                              class="form-control"
                              placeholder="dd/mm/aaaa hh:mm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Fornecedor</label>
                        <input
                          id="searchCompras-destinatario"
                          v-model="searchCompras.destinatario"
                          type="text"
                          class="form-control"
                          placeholder="Nome do Fornecedor"
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    @click="getData"
                    class="btn btn-primary"
                    type="button"
                  >
                    OK
                  </button>
                </form>
              </div>
            </div>
            <!-- End Card -->
          </div>
          <div class="card-body">
            <!-- mode de faturamento -->
            <b-modal
              v-model="faturando"
              size="xl"
              title="Faturamento"
              hide-footer
              @hidden="faturando_fecha()"
            >
              <div class="row">
                <div class="col-md-12">
                  <widget />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <label for="">Saldos:</label>
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <div class="row">
                          <div
                            class="col-md-3"
                            v-for="(saldo, index) in saldos"
                            :key="(index = saldo.id)"
                          >
                            <div class="card">
                              <b-form-checkbox>
                                <b-badge
                                  data-name="Total"
                                  class="field-status"
                                  variant="success"
                                  >{{ saldo.saldo }}</b-badge
                                >
                                :: pgto :
                                <b-badge
                                  data-name="Total"
                                  class="field-status"
                                  variant="danger"
                                  >{{ saldo.id }}</b-badge
                                >
                              </b-form-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label for="">Comprador:</label>
                  <!-- pessoas -->
                  <multiselect v-model="select_comprador" :options="pessoas" label="pes_fantasia">
                  </multiselect>
                </div>
                <div class="col-md-6">
                  <label for="">Plano de Contas :</label>
                  <multiselect v-model="select_plano" :options="tp_contas" label="descricao">
                  </multiselect>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <label for="">Condições de Pagamento:</label>
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12">
                          <b-form-checkbox value="1" id="checkbox5">
                            Com entrada -
                            <Label>Dividir em:</Label>
                            <input
                              v-model="gerar.dividir"
                              type="number"
                              min="1"
                              class="estilo_input mr-4 ml-4"
                            />
                            <Label>com prazo de: </Label>
                            <input
                              v-model="gerar.prazo_dias"
                              type="number"
                              min="1"
                              class="estilo_input mr-4 ml-4"
                            />
                            {{ "dias" }}
                            <button
                              @click="gerar_parcelas()"
                              class="btn btn-secondary ml-4"
                            >
                              Gerar Parcelas
                            </button>
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <!-- <b-table-simple responsive>
                    <b-thead id="b-head">
                      <b-tr>
                        <b-th>&nbsp;</b-th>
                        <b-th>Vencimento</b-th>
                        <b-th>Esp Pagamento</b-th>
                        <b-th>Conta</b-th>
                        <b-th>DF</b-th>
                        <b-th>N doc</b-th>
                        <b-th>Descrição</b-th>
                        <b-th>Valor</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr >
                        <td></td>
                        <td>
                          <input
                            type="date"
                            class="form-control text-left largura1"
                          />
                        </td>
                        <td>
                          <multiselect
                            class="largura"
                            :options="tp_pagamentos"
                            label="tiponome"
                          ></multiselect>
                        </td>
                        <td>
                          <multiselect
                            class="largura"
                            :options="tp_contas"
                            label="descricao"
                          ></multiselect>
                        </td>
                        <td>
                          <b-form-checkbox
                            class="largura"
                            value="5"
                            id="checkbox6"
                          >
                          </b-form-checkbox>
                        </td>
                        <td>
                          <input
                            type="text"
                            name="n_doc"
                            class="form-control text-left largura2"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="descrição"
                            class="form-control text-left largura2"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="valor"
                            class="form-control text-left largura2"
                          />
                        </td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple> -->
                  <b-table
                    striped
                    hover
                    :items="fatura_vet"
                    responsive
                    id="doc-itens"
                    :fields="fields_fatura"
                  >
                    <template #cell(tp_pagamento)="row">
                      {{
                        row.item.tp_pagamento === 1
                          ? "Dinheiro"
                          : row.item.tp_pagamento === 2
                          ? "Duplicata"
                          : row.item.tp_pagamento === 3
                          ? "Cheque"
                          : row.item.tp_pagamento === 4
                          ? "Cartão de Credito"
                          : row.item.tp_pagamento === 5
                          ? "Cartão de Debito"
                          : row.item.tp_pagamento === 6
                          ? "Cupom Devolução"
                          : row.item.tp_pagamento === 7
                          ? "Transferencia"
                          : row.item.tp_pagamento === 8
                          ? "Vale Alimentação"
                          : row.item.tp_pagamento === 9
                          ? "Vale Refeição"
                          : row.item.tp_pagamento === 10
                          ? "Vale Presente"
                          : row.item.tp_pagamento === 11
                          ? "Vale Combustivel"
                          : row.item.tp_pagamento === 12
                          ? "Sem Pagamento"
                          : "Outros"
                      }}
                    </template>
                  
                    <template #cell(excluir)="row">
                      <button
                        @click="Delete_fatura(row.item)"
                        class="btn btn-danger"
                      >
                        Excluir
                      </button>
                    </template>
                    <template #cell(vecimento)="row">
                      <!-- <span>{{row.item.vecimento}}</span> -->
                      <input
                        v-model="row.item.vecimento"
                        type="date"
                        class="estilo_input1"
                        @click="select_vecimento(row.item)"
                      />
                    </template>
                    <template #cell(esp_pag)="row">
                      <multiselect
                        v-model="row.item.esp_pag"
                        class="larg_mult"
                        :options="tp_pagamentos"
                        label="tiponome"
                        @select="select_tp_pagamento(row.item)"
                      ></multiselect>
                    </template>
                    <template #cell(conta)="row">
                      <multiselect
                        v-model="row.item.conta"
                        class="larg_mult"
                        :options="tp_contas"
                        label="descricao"
                        @select="select_conta(row.item)"
                      ></multiselect>
                      <!-- <input type="text" class="estilo_input1" @click="select_conta(row.item)" > -->
                    </template>
                    <template #cell(n_doc)="row">
                      <input
                        v-model="row.item.n_doc"
                        type="text"
                        class="estilo_input1"
                        @click="select_doc(row.item)"
                      />
                    </template>
                    <template #cell(desc)="row">
                      <input
                        v-model="row.item.desc"
                        type="text"
                        class="estilo_input1"
                        @click="select_desc(row.item)"
                      />
                    </template>
                    <template #cell(valor)="row">
                      <input
                        v-model="row.item.valor"
                        type="text"
                        class="estilo_input1"
                        @click="select_valor(row.item)"
                      />
                    </template>
                    <!-- <template #cell(excluir2)="row">
              <multiselect v-model="nada" @select="fat(row.item)" label="tiponome" :options="pagamentos_tp"></multiselect>
            </template> -->
                    <!-- <template #cell(tipo_pagamento_id)="row">
              {{ (row.item.tipo_pagamento_id === 1)  ? 'Dinheiro' 
              :  (row.item.tipo_pagamento_id === 2)  ? 'Duplicata' 
              :  (row.item.tipo_pagamento_id === 3)  ? 'Cheque' 
              :  (row.item.tipo_pagamento_id === 4)  ? 'Cartão de Credito' 
              :  (row.item.tipo_pagamento_id === 5)  ? 'Cartão de Debito' 
              :  (row.item.tipo_pagamento_id === 6)  ? 'Cupom Devolução' 
              :  (row.item.tipo_pagamento_id === 7)  ? 'Transferencia' 
              :  (row.item.tipo_pagamento_id === 8)  ? 'Vale Alimentação' 
              :  (row.item.tipo_pagamento_id === 9)  ? 'Vale Refeição' 
              :  (row.item.tipo_pagamento_id === 10) ? 'Vale Presente' 
              :  (row.item.tipo_pagamento_id === 11) ? 'Vale Combustivel' 
              :  (row.item.tipo_pagamento_id === 12) ? 'Sem Pagamento' : 'Outros' 
                }}
            </template> -->
                  </b-table>
                  <hr />
                </div>
              </div>
              <div class="row d-flex justify-content-right align-items-right">
                <div class="col-md-12 text-right">
                  <div class="cerd">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12 mb-2">
                          <label for="">Total -></label>
                          <input id="margin" class="estilo_input" type="text" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <b-form-checkbox value="3">
                            Ajustando Valores Automaticamente
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <label for="">Observações:</label>
                      <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                        class="form-control"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row d-flex justify-content-center align-items-center">
                <div class="col-md-12 text-center">
                  <button
                    class="btn btn-secondary"
                    @click="fat_compra_open_modal()"
                  >
                    Faturar
                  </button>
                </div>
              </div>
            </b-modal>
            <b-modal
              v-model="faturando_compra"
              size="xl"
              title="Faturamento-1"
              hide-footer
              @hidden="fat_compra_open_modal_fecha()"
            >
              <div class="row">
                <div class="col-md-12">
                  <widget />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <label for="">Saldo 1:</label>
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <div class="row">
                          <div
                            class="col-md-3"
                            v-for="(saldo, index) in saldos"
                            :key="(index = saldo.id)"
                          >
                            <div class="card">
                              <b-form-checkbox>
                                {{ saldo.saldo }} :: pgto : {{ saldo.id }}
                              </b-form-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label for="">Comprador:</label>
                  <input type="text" class="form-control text-left" />
                </div>
                <div class="col-md-6">
                  <label for="">Plano de Contas :</label>
                  <multiselect :options="planos" label="descricao">
                  </multiselect>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <label for="">Condições de Pagamento:</label>
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12">
                          <b-form-checkbox value="1" id="checkbox5">
                            Com entrada -
                            <Label>Dividir em:</Label>
                            <input type="number" min="1" />

                            <Label>com parcelas de: </Label>
                            <input type="number" min="1" />
                            {{ "dias" }}
                            <button class="btn btn-secondary">
                              Gerar Parcelas
                            </button>
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <b-table-simple>
                    <b-thead id="b-head">
                      <b-tr>
                        <b-th>Vencimento</b-th>
                        <b-th>Esp Pagamento</b-th>
                        <b-th>Conta</b-th>
                        <b-th>DF</b-th>
                        <b-th>N doc</b-th>
                        <b-th>Descrição</b-th>
                        <b-th>Valor</b-th>
                      </b-tr>
                    </b-thead>
                  </b-table-simple>
                </div>
              </div>
              <div class="row d-flex justify-content-center align-items-center">
                <div class="col-md-12 text-center">
                  <button class="btn btn-secondary" @click="post_faturamento()">
                    Salvar
                  </button>
                </div>
              </div>
            </b-modal>
            <!--final  modal de faturamento -->
            <b-modal
              v-model="finalizando_estabelece"
              size="xg"
              title="Finalizando"
              hide-footer
              @hidden="finalizando_back()"
            >
              <div class="card">
                <div class="card-body">
                  <div
                    class="row d-flex justify-content-center align-items-center"
                  >
                    <div class="col-md-12 text-center">
                      <button id="largura" class="btn btn-primary">
                        Imprimir o Pedido
                      </button>
                    </div>
                  </div>
                  <div
                    class="row d-flex justify-content-center align-items-center"
                  >
                    <div class="col-md-12 text-center">
                      <button
                        id="largura"
                        class="btn btn-primary"
                        @click="faturando_abre()"
                      >
                        Faturar o Pedido
                      </button>
                    </div>
                  </div>
                  <div
                    class="row d-flex justify-content-center align-items-center"
                  >
                    <div class="col-md-12 text-center">
                      <button id="largura" class="btn btn-primary">
                        Ir para a ficha de {{ "Falluno" }}
                      </button>
                    </div>
                  </div>
                  <div
                    class="row d-flex justify-content-center align-items-center"
                  >
                    <div class="col-md-12 text-center">
                      <button id="largura" class="btn btn-primary">
                        Registrar um novo Pedido
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </b-modal>
            <b-modal
              v-model="estabelece_veri"
              size="xl"
              title="Estabelecimento de Compra"
              hide-footer
              @hidden="estabelecendo_voltar()"
            >
              <div class="row">
                <div class="col-md-3">
                  <label for="">Tipo:</label>
                  <!-- <input
                    class="form-control text-left"
                    type="text"
                    placeholder="Gerado pelo sistema"
                    id="pedido_id"
                  /> -->
                  <multiselect
                    v-model="select_tipo"
                    :options="tipos"
                    label="nome"
                  >
                  </multiselect>
                </div>
                <div class="col-md-3">
                  <label for="">Fornecedor:</label>
                  <multiselect
                    v-model="select_pessoas"
                    :options="pessoas"
                    label="pes_fantasia"
                  >
                  </multiselect>
                </div>
                <div class="col-md-2">
                  <label for="">Data:</label>
                  <input
                    v-model="inicial.data"
                    class="form-control text-left"
                    type="date"
                    placeholder="Gerado pelo sistema"
                    id="pedido_id"
                  />
                </div>
                <div class="col-md-2">
                  <label for="">Nota Fiscal:</label>
                  <input
                    v-model="inicial.nota"
                    class="form-control text-left"
                    type="text"
                    placeholder="Gerado pelo sistema"
                    id="pedido_id"
                  />
                </div>
                <div class="col-md-2">
                  <label for="">Valor:</label>
                  <input
                    v-model="inicial.valor"
                    class="form-control text-left"
                    type="text"
                    placeholder="Gerado pelo sistema"
                    id="pedido_id"
                  />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-md-12">
                  <Label>Items:</Label>
                  <!-- Essa tabela tem que ter um v-for para receber os items 
                  do predidod , pois pode vim mais de um  -->
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12">
                          <b-table-simple>
                            <b-thead id="b-head">
                              <b-tr>
                                <!-- Essa tabela tem que ter um v-for para receber os items 
                  do predidod , pois pode vim mais de um  -->
                                <b-th>&nbsp;</b-th>
                                <b-th>Quantidade Recebida</b-th>
                                <b-th>Quantidade Final</b-th>
                                <b-th>Preço</b-th>
                                <b-th>Valor</b-th>
                                <b-th>Aliquota</b-th>
                                <b-th>Cst</b-th>
                              </b-tr>
                            </b-thead>
                            <b-tbody>
                              <b-tr>
                                <b-td>{{ "Maracuja Fruta" }}</b-td>
                                <b-td
                                  ><input
                                    placeholder="digite"
                                    @input="formatInput_qtd_recebida()"
                                    v-model="item.qtd_recebida"
                                    type="text"
                                    name="qtd_receber"
                                    class="form-control text-left"
                                    value="1.800"
                                /></b-td>
                                <b-td
                                  ><input
                                    placeholder="digite"
                                    @input="formatInput_qtd_final(), calcular()"
                                    v-model="item.qtd_final"
                                    type="text"
                                    name="qtd_final"
                                    class="form-control text-left"
                                /></b-td>
                                <b-td
                                  ><input
                                    placeholder="digite"
                                    @input="formatInput_preco(), calcular()"
                                    v-model="item.preco"
                                    type="text"
                                    name="preco"
                                    class="form-control text-left"
                                    value="1.800"
                                /></b-td>
                                <b-td
                                  ><input
                                    placeholder="digite"
                                    disabled
                                    @input="formatInput_valor()"
                                    v-model="item.valor"
                                    type="text"
                                    name="valor"
                                    class="form-control text-left"
                                    value="26%"
                                /></b-td>
                                <b-td
                                  ><input
                                    placeholder="digite"
                                    @input="formatInput_aliquota()"
                                    v-model="item.aliquota"
                                    type="text"
                                    name="aliquota"
                                    class="form-control text-left"
                                    value="26%"
                                /></b-td>
                                <b-td
                                  ><input
                                    placeholder="digite"
                                    @input="formatInput_Cst()"
                                    v-model="item.Cst"
                                    type="text"
                                    name="cst"
                                    class="form-control text-left"
                                    value="Cst"
                                /></b-td>
                              </b-tr>
                            </b-tbody>
                          </b-table-simple>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row d-flex justify-content-center align-items-center">
                <div class="col-md-12 text-center">
                  <label id="fonte" for=""
                    >{{ tot_sub }} R$ :
                    <b-badge
                      data-name="Total"
                      class="field-status"
                      variant="success"
                      >{{ total_valor }}</b-badge
                    ></label
                  >
                </div>
              </div>
              <div class="row d-flex justify-content-center align-items-center">
                <div class="col-md-12 text-center">
                  <!-- @change="intermed_veri(1)" -->
                  <b-form-checkbox
                    value="1"
                    @change="outros_valores($event)"
                    id="checkbox1"
                  >
                    Adicionar OUtros Valores
                  </b-form-checkbox>
                </div>
              </div>
              <div
                v-if="valores_add"
                class="row d-flex justify-content-center align-items-center"
              >
                <div class="col-md-12 col-md-12 text-center">
                  <!-- @change="intermed_veri(1)" -->
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12 text-right">
                          <label for="" class="mr-2">Base de Calculo -></label>
                          <input
                            @input="formatInput_bs_calculo()"
                            v-model="outro_valor.bs_calculo"
                            type="text"
                            class="estilo_input"
                            placeholder="Digite"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 text-right">
                          <label for="" class="mr-2">Taxa % -> </label>
                          <input
                            @input="formatInput_taxa()"
                            v-model="outro_valor.taxa"
                            type="text"
                            class="estilo_input"
                            placeholder="Digite"
                          />
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-md-12 text-right">
                          <label for="" class="mr-2"
                            >Redução(INSS) {{ "-> " }}{{ "-"
                            }}{{ " R$" }}</label
                          >
                          <input
                            placeholder="Digite"
                            @input="formatInput_red_inss()"
                            v-model="outro_valor.red_inss"
                            type="text"
                            class="estilo_input"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 text-right">
                          <label for="" class="mr-2"
                            >Frete {{ "-> " }}{{ "+" }}{{ " R$" }}</label
                          >
                          <input
                            placeholder="Digite"
                            @input="formatInput_frete()"
                            v-model="outro_valor.frete"
                            type="text"
                            class="estilo_input"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 text-right">
                          <label for="" class="mr-2"
                            >Desconto {{ "-> " }}{{ "-" }}{{ " R$" }}</label
                          >
                          <input
                            placeholder="Digite"
                            @input="formatInput_desconto()"
                            v-model="outro_valor.desconto"
                            type="text"
                            class="estilo_input"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 text-right">
                          <b-form-checkbox value="1" @change="funrural($event)">
                            Definir FunRural
                            <label for="" class="mr-2"
                              >{{ "-> " }}{{ sigla }}{{ " R$" }}</label
                            >
                            <input
                              placeholder="Digite"
                              @input="formatInput_funrural()"
                              v-model="outro_valor.funrural"
                              type="text"
                              class="estilo_input"
                              :disabled="test_fun_rural"
                            />
                          </b-form-checkbox>
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-md-12 text-right">
                          <label id="fonte" for=""
                            >Total R$ :
                            <b-badge
                              data-name="Total"
                              class="field-status"
                              variant="success"
                              >{{ total_valor2 }}</b-badge
                            ></label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <div
                    class="row d-flex justify-content-center align-items-center"
                  >
                    <div class="col-md-12 text-left">
                      <label for="">Observações:</label>
                      <textarea
                        v-model="observacoes"
                        class="form-control text-left"
                        name=""
                        id=""
                        cols="60"
                        rows="10"
                      >
                      </textarea>
                    </div>
                  </div>
                </div>
                <div
                  class="card-body d-flex justify-content-center align-items-center"
                  id="top"
                >
                  <div class="col-md-12 mt-12 text-center">
                    <!-- Adicionado "text-center" aqui -->
                    <button
                      class="btn btn-secondary"
                      id="butt"
                      @click="finalizando()"
                    >
                      Estabelecer Compra
                    </button>
                  </div>
                </div>
              </div>
            </b-modal>
          </div>
          <List
            @estabelecendo="estabelecendo"
            :currentEmpresa="currentEmpresa"
            :listPedidos="listPreCompra"
            :isBusy="loader.get"
          />
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
/* .borda{
  border: ;
} */
.estilo_input1 {
  width: 130px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
#fonte {
  font-size: 20px;
}
#largura {
  width: 400px;
  margin-bottom: 10px;
}

#margin {
  margin-left: 10px;
}

.largura {
  width: 110%;
}

.largura1 {
  width: 108%;
}

.largura2 {
  width: 100%;
}

#borda {
  border: solid 2px black;
  /* border-radius: 12px; */
  width: 100%;
}

#esquerda {
  right: 100px;
}

.esquerda {
  left: 100px;
}

.estilo_input {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>